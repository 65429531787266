import 'vanilla-cookieconsent/dist/cookieconsent.css'
import '@orestbida/iframemanager/dist/iframemanager.css'
import * as CookieConsent from 'vanilla-cookieconsent'
import '@orestbida/iframemanager/dist/iframemanager.js'

const im = iframemanager()

im.run({
  onChange: ({ changedServices, eventSource }) => {
    if (eventSource.type === 'click') {
      const servicesToAccept = [
        ...CookieConsent.getUserPreferences().acceptedServices['embed'],
        ...changedServices,
      ]

      CookieConsent.acceptService(servicesToAccept, 'embed')
    }
  },

  currLang: 'de',

  services: {
    youtube: {
      embedUrl: 'https://www.youtube-nocookie.com/embed/{data-id}',
      thumbnailUrl: 'https://i3.ytimg.com/vi/{data-id}/hqdefault.jpg',

      iframe: {
        allow:
          'accelerometer; encrypted-media; gyroscope; picture-in-picture; fullscreen;',
      },

      languages: {
        en: {
          notice:
            'This content is hosted by a third party. By showing the external content you accept the <a rel="noreferrer noopener" href="https://www.youtube.com/t/terms" target="_blank">terms and conditions</a> of youtube.com.',
          loadBtn: 'Load once',
          loadAllBtn: 'Don\'t ask again',
        },
        de: {
          notice: '<h2 class="text-2xl md:text-5xl mb-4 md:mb-12">Youtube</h2>Dieser Inhalt wird von einem externen Anbieter gehostet. Durch die Anzeige des externen Inhalts akzeptieren Sie die <a rel="noreferrer" href="https://www.youtube.com/t/terms" title="Bedingungen und Konditionen" target="_blank">Bedingungen und Konditionen</a> von youtube.com.',
          loadBtn: 'Video laden',
          loadAllBtn: 'Nicht nochmal fragen',
        }
      },
    },

    googlemaps: {
      embedUrl: '{data-id}',
      iframe: {
        allow: 'fullscreen;',
        onload: (dataId, setThumbnail) => {
          const container = document.querySelector(`div[data-id="${dataId}"]`)
          if (container) {
            container.classList.add('iframe-container--loaded')
          }
        }
      },
      cookie: {
        name: 'cc_maps'
      },
      languages: {
        'de': {
          notice: '<h2 class="text-2xl md:text-5xl mb-4 md:mb-12">Google Maps</h2> Inhalt wird von einem externen Anbieter gehostet. Durch die Anzeige des externen Inhalts akzeptieren Sie die Bedingungen und Konditionen von google.com.',
          loadBtn: 'Map laden',
          loadAllBtn: 'Nicht nochmal fragen'
        }
      }
    },

    calendar: {
      embedUrl: '{data-id}',
      iframe: {
        allow: 'fullscreen;'
      },
      languages: {
        'de': {
          notice: '<h2 class="text-2xl md:text-5xl mb-4 md:mb-12">Termine</h2> Hier geht es zum Terminkalender! Durch das Laden des Kalenders stimmt Ihr der Übermittlung eurer Daten zur Terminvereinbarung zu. <a href="https://www.iie-systems.de/service/datenschutz/" target="_blank">iie systems</a>',
          loadBtn: 'Kalender laden',
          loadAllBtn: 'Nicht nochmal fragen'
        }
      }
    },

    vimeo: {
      embedUrl: 'https://player.vimeo.com/video/{data-id}',
      iframe: {
        allow: 'fullscreen; picture-in-picture;',
      },

      thumbnailUrl: async (dataId, setThumbnail) => {
        const url = `https://vimeo.com/api/v2/video/${dataId}.json`
        const response = await (await fetch(url)).json()
        const thumbnailUrl = response[0]?.thumbnail_large
        thumbnailUrl && setThumbnail(thumbnailUrl)
      },

      languages: {
        en: {
          notice:
            'This content is hosted by a third party. By showing the external content you accept the <a rel="noreferrer noopener" href="https://vimeo.com/terms" target="_blank">terms and conditions</a> of vimeo.com.',
          loadBtn: 'Load once',
          loadAllBtn: 'Don\'t ask again',
        },
        de: {
          notice:
            '<h2 class="text-2xl" style="margin-bottom: 10px">Vimeo</h2>Dieser Inhalt wird von einem externen Anbieter gehostet. Durch die Anzeige des externen Inhalts akzeptieren Sie die <a rel="noreferrer noopener" href="https://vimeo.com/terms" target="_blank">Bedingungen und Konditionen</a> von vimeo.com.',
          loadBtn: 'Video laden',
          loadAllBtn: 'Nicht nochmal fragen',
        },
      },
    },
  },
})

CookieConsent.run({
  guiOptions: {
    consentModal: {
      layout: 'box wide',
      position: 'bottom center',
      equalWeightButtons: true,
      flipButtons: false,
    },
    preferencesModal: {
      layout: 'box',
      equalWeightButtons: true,
      flipButtons: false,
    },
  },

  categories: {
    necessary: {
      readOnly: true,
      enabled: true,
    },
    analytics: {
      readOnly: false,
      autoClear: {
        cookies: [
          {
            name: /^(_ga)/,
          }
        ]
      }
    },
    embed: {
      services: {
        youtube: {
          label: 'Youtube Embed',
          onAccept: () => im.acceptService('youtube'),
          onReject: () => im.rejectService('youtube'),
        },
        vimeo: {
          label: 'Vimeo Embed',
          onAccept: () => im.acceptService('vimeo'),
          onReject: () => im.rejectService('vimeo'),
        },
        googlemaps: {
          label: 'Google Maps Embed',
          onAccept: () => im.acceptService('googlemaps'),
          onReject: () => im.rejectService('googlemaps'),
        },
        calendar: {
          label: 'Terminkalender Embed',
          onAccept: () => im.acceptService('calendar'),
          onReject: () => im.rejectService('calendar'),
        },
      },
    },
  },

  language: {
    default: 'de',
    translations: {
      de: {
        'consentModal': {
          'label': 'Cookie-Einwilligung',
          'title': 'Hallo Reisender, es ist Zeit für Cookies!',
          'description': 'Um unsere Website optimal zu gestalten, fortlaufend zu verbessern und Ihnen interessengerechte Inhalte anzuzeigen, verwenden wir Cookies.',
          'acceptAllBtn': 'Alle akzeptieren',
          'closeIconLabel': 'Alle ablehnen und schließen',
          'acceptNecessaryBtn': 'Alle ablehnen',
          'showPreferencesBtn': 'Einstellungen verwalten',
          'footer': '<a href="/datenschutz">Datenschutzerklärung</a>'
        },
        'preferencesModal': {
          'title': 'Zentrum für Einwilligungseinstellungen',
          'acceptAllBtn': 'Alle akzeptieren',
          'acceptNecessaryBtn': 'Alle ablehnen',
          'savePreferencesBtn': 'Einstellungen speichern',
          'closeIconLabel': 'Modal schließen',
          'serviceCounterLabel': 'Service|Dienste',
          'sections': [
            {
              'title': 'Hat jemand ... Cookies gesagt?',
              'description': 'Hier können Sie einsehen und anpassen, welche Information wir über Sie speichern. Um mehr zu erfahren, lesen Sie bitte unsere <a href="/datenschutz">Datenschutzerklärung</a>.'
            },
            {
              'title': 'Technisch notwendige Cookies <span class="pm__badge">Immer aktiviert</span>',
              'description': 'Notwendige Cookies die gebraucht werden um die Webseite zu benutzen.',
              'linkedCategory': 'necessary'
            },
            {
              'title': 'Google Analytics',
              'description': 'Wir nutzen Google Analytics, um anonymisierte Daten zur Nutzung unserer Website zu sammeln. Diese helfen uns, unsere Inhalte zu verbessern.',
              'linkedCategory': 'analytics',
              'cookieTable': {
                'headers': {
                  'name': 'Cookie',
                  'Service': 'Service',
                  'description': 'Description'
                },
              }
            },
            {
              'title': 'Embedded Content',
              'description': 'Eingebettete Inhalte von Drittanbietern wie YouTube, Vimeo, Terminkalender und Google Maps verwenden Cookies, um die Video-Wiedergabe, Interaktion mit dem Video-Player, die Anzeige von Terminen sowie interaktive Karten zu ermöglichen. Dabei werden auch personenbezogene Daten wie IP-Adressen erfasst.',
              'linkedCategory': 'embed',
              'cookieTable': {
                'headers': {
                  'name': 'Cookie',
                  'Service': 'Service',
                  'description': 'Beschreibung'
                }
              }
            }
          ]
        }
      },
    },
  },
})

window.showCookieSettings = function () {
  CookieConsent.showPreferences()
}
